export const desc1 = `Sou apaixonada pela arquitetura e em como ela pode mudar a vida das 
pessoas,adoro ler e conhecer novos lugares nas horas vagas. Sou motivada pelo desafio e me 
esforço para dar o meu melhor em tudo o que me proponho a fazer. Meu grande sonho é tornar meu 
trabalho acessível e levar qualidade de vida a todos.`

export const desc2 = `Sou apaixonado por pessoas,tenho a firme convicção de que meu propósito 
está voltado e direcionado para o “outro”,ele não começa e nem termina em mim.`

export const desc21 = `Tenho comigo a Petrus o meu ponto de partida e as pessoas, a minha jornada e o 
meu destino. Carrego como um grande sonho, servir e ajudar o máximo de pessoas que conseguir nessa jornada.
Contudo, confio firmemente que para um negócio ser sólido e promissor ele deve ser planejado e direcionado 
para o MUNDO e não para seu "dono". Desta maneira, é um grande prazer para mim, poder servir você.`